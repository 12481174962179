
import { defineComponent, onMounted, Ref, ref, watch } from 'vue';

import Editor from '@tinymce/tinymce-vue';

export default defineComponent({
	props: {
		modelValue: String,
		height: Number,
		disabled: Boolean,
	},

	components: {
		Editor,
	},

	setup(props, ctx) {
		const html: Ref<string> = ref('');

		// Update the model value when html is changed
		watch(html, (value) => {
			ctx.emit('update:modelValue', value);
		});

		// Set HTML equal to the model value when mounting
		onMounted(() => {
			html.value = typeof props.modelValue !== 'string' ? '' : props.modelValue;
		});

		// Set HTML equal to the model value when there is a change in the model value (model value is loaded asynchronously)
		watch(
			() => props.modelValue,
			(value) => {
				html.value = value || '';
			}
		);

		return {
			// data
			html,
		};
	},
});
