
import { computed, defineComponent, onMounted, reactive, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';

import ProjectService from '@/services/project.service';
import Project from '@/models/project.model';

import Layout from '@/components/Layout.vue';
import Editor from '@/components/form/Editor.vue';
import Input from '@/components/form/Input.vue';
import Loader from '@/components/Loader.vue';
import Store from '@/store';

export default defineComponent({
	components: {
		Layout,
		Editor,
		Input,
		Loader,
	},

	setup() {
		const route = useRoute();
		const user = Store.get('user');

		const project: Ref<Project | null> = ref(null);

		const pageLoading = ref(true);
		const loading = ref(false);

		const form = reactive({
			vision: '',
			goal: '',
			purpose: '',
			limit: '',
			fibonacciNumber: 1,
			hourlyRate: 200,
		});

		const backupForm = reactive({
			vision: '',
			goal: '',
			purpose: '',
			limit: '',
			fibonacciNumber: 1,
			hourlyRate: 200,
		});

		const taskPoints = reactive({
			backlog: 0,
			doing: 0,
			done: 0,
			total: 0,
		});

		// Points
		const backlogPoints = computed(() => {
			return form.fibonacciNumber * taskPoints.backlog;
		});

		const doingPoints = computed(() => {
			return form.fibonacciNumber * taskPoints.doing;
		});

		const donePoints = computed(() => {
			return form.fibonacciNumber * taskPoints.done;
		});

		const totalPoints = computed(() => {
			return form.fibonacciNumber * taskPoints.total;
		});

		// Values
		const backlogValue = computed(() => {
			return form.hourlyRate * backlogPoints.value;
		});

		const doingValue = computed(() => {
			return form.hourlyRate * doingPoints.value;
		});

		const doneValue = computed(() => {
			return form.hourlyRate * donePoints.value;
		});

		const totalValue = computed(() => {
			return form.hourlyRate * totalPoints.value;
		});

		onMounted(async () => {
			// Load Project & Task Groups
			await ProjectService.show(Number(route.params.id))
				.then((response) => {
					if (response) {
						project.value = response;

						form.vision = response?.vision || '';
						form.goal = response?.goal || '';
						form.purpose = response?.purpose || '';
						form.limit = response?.limit || '';
						form.fibonacciNumber = response?.fibonacci_factor || 1;
						form.hourlyRate = response?.hourly_rate || 200;

						backupForm.vision = response?.vision || '';
						backupForm.goal = response?.goal || '';
						backupForm.purpose = response?.purpose || '';
						backupForm.limit = response?.limit || '';
						backupForm.fibonacciNumber = response?.fibonacci_factor || 1;
						backupForm.hourlyRate = response?.hourly_rate || 200;

						response.tasks.forEach((task) => {
							const status = task?.status?.title;
							if (status !== 'Archieved') {
								taskPoints.total += task.point;
							}

							switch (status) {
								case 'In Backlog':
									taskPoints.backlog += task.point;
									break;
								case 'In Sprintlog':
									taskPoints.backlog += task.point;
									break;
								case 'Doing':
									taskPoints.doing += task.point;
									break;
								case 'Waiting':
									taskPoints.doing += task.point;
									break;
								case 'Done':
									taskPoints.done += task.point;
									break;
								default:
									break;
							}
						});
					}
				})
				.finally(() => {
					pageLoading.value = false;
				});
		});

		// Functions

		async function updateVision() {
			if (form.vision === backupForm.vision) {
				return;
			}

			if (form.vision.length && project.value !== null) {
				loading.value = true;

				await ProjectService.update(project.value.id, {
					vision: form.vision,
				}).finally(() => {
					loading.value = false;
				});
			}
		}

		async function updateGoal() {
			if (form.goal === backupForm.goal) {
				return;
			}

			if (form.goal.length && project.value !== null) {
				loading.value = true;

				await ProjectService.update(project.value.id, {
					goal: form.goal,
				}).finally(() => {
					loading.value = false;
				});
			}
		}

		async function updatePurpose() {
			if (form.purpose === backupForm.purpose) {
				return;
			}

			if (form.purpose.length && project.value !== null) {
				loading.value = true;

				await ProjectService.update(project.value.id, {
					purpose: form.purpose,
				}).finally(() => {
					loading.value = false;
				});
			}
		}

		async function updateLimit() {
			if (form.limit === backupForm.limit) {
				return;
			}

			if (form.limit.length && project.value !== null) {
				loading.value = true;

				await ProjectService.update(project.value.id, {
					limit: form.limit,
				}).finally(() => {
					loading.value = false;
				});
			}
		}

		async function updateFibonacci() {
			if (form.fibonacciNumber === backupForm.fibonacciNumber) {
				return;
			}

			if (form.fibonacciNumber && !isNaN(form.fibonacciNumber * 1) && project.value !== null) {
				loading.value = true;

				await ProjectService.update(project.value.id, {
					fibonacci_factor: form.fibonacciNumber,
				}).finally(() => {
					loading.value = false;
				});
			}
		}

		async function updateHourly() {
			if (form.hourlyRate === backupForm.hourlyRate) {
				return;
			}

			if (form.hourlyRate && !isNaN(form.hourlyRate * 1) && project.value !== null) {
				loading.value = true;

				await ProjectService.update(project.value.id, {
					hourly_rate: form.hourlyRate,
				}).finally(() => {
					loading.value = false;
				});
			}
		}

		return {
			// data
			user,
			form,
			loading,
			pageLoading,

			// computed
			backlogPoints,
			doingPoints,
			donePoints,
			totalPoints,

			backlogValue,
			doingValue,
			doneValue,
			totalValue,

			// functions
			updateVision,
			updateGoal,
			updatePurpose,
			updateLimit,
			updateFibonacci,
			updateHourly,
		};
	},
});
