import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor", true)!

  return (_openBlock(), _createBlock(_component_Editor, {
    "api-key": "6n8jn8bqiudjymp2sagki9qcxxf1qlsw6z7zpw9ud5um8no0",
    modelValue: _ctx.html,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.html) = $event)),
    class: "rounded",
    disabled: _ctx.disabled,
    init: {
			menubar: false,
			height: _ctx.height,
			plugins: [
				'advlist autolink lists link image charmap print preview anchor',
				'searchreplace visualblocks code fullscreen',
				'insertdatetime media table paste code help wordcount',
			],
			toolbar: 'undo redo | formatselect | bold italic | bullist numlist outdent indent | removeformat',
		}
  }, null, 8, ["modelValue", "disabled", "init"]))
}